<template>
  <div class="">
    <div class="account" v-if="!!currentUser">
      <h2 class="text-blue mb-4">Choose Account</h2>
      <Select
        class="mb-4"
        :options="organizations"
        v-model="organization"
        :allowEmpty="false"
      >
      </Select>
      <div class="d-flex align--center justify--center">
        <button
          type="button"
          class="btn btn-primary btn-sm btn-block"
          @click="setAccount"
          :disabled="!organization"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'account',
  data() {
    return {
      organization: null
    }
  },

  computed: {
    ...mapGetters([
      'isLoading',
      'currentUser',
      'organizations',
      'currentTenant',
      'reloadTabledata'
    ])
  },
  methods: {
    setAccount() {
      this.$store.commit('setCurrentTenant', this.organization)
      localStorage.setItem('organizationId', this.organization.id)
      this.$router.push({ name: 'private' })
    }
  }
}
</script>

<style lang="scss">
.account {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h2 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: 2rem;
    color: #2f8fcf;
  }
  .accounts-list {
    margin: 4rem 0;
  }
  width: 21.375rem;

  .down-container {
    margin-top: 3.125rem;
  }
}
</style>
